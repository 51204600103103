import "./App.css";
import { BsInstagram, BsWhatsapp } from "react-icons/bs";
import { MdCopyright } from "react-icons/md";
import logo from "./assets/logo.svg";

function App() {
  return (
    <>
      <div className="App">
        <main >
          <img
            className="logo"
            src={logo}
            alt="logo"
          ></img>
          <p style={{ color: "#283B91", fontSize: 20, marginBottom: 0 }}>
            We'll be here soon
          </p>
          <p
            style={{
              color: "#283B91",
              opacity: "50%",
              fontSize: 20,
              marginTop: 0,
              marginBottom: 40,
            }}
          >
            Estaremos aqui em breve
          </p>
          <a  className="btn" 
              href="https://wa.me/13478704216">
              <BsWhatsapp /> WhatsApp
          </a>
          <p style={{ marginTop:30}}>
            <a
              style={{ textDecoration: "none", color: "#1ED760", fontSize: 20}}
              href="mailto:info@fontoura.us?subject=Website Contact&body=Message"
            >
              info@fontoura.us
            </a>
          </p>
        </main>
      </div>
      <footer
        className="logo-right"
        style={{ textAlign: "center" }}
      >
        <a  style={{ fontSize: 20}}
          className="insta"
          href='https://www.instagram.com/fontoura.us/'
        >
          <BsInstagram />
        </a>
        <p className="copy">
          <MdCopyright /> 2022 Fontoura Consultants. All Rights Reserved
        </p>
      </footer>
    </>
  );
}

export default App;
